import * as commonApi from '@apis/common';
import {
  PaymentStatement,
  PaymentStatementPaymentStatus,
  TossCheckoutParams,
  TossPaymentsConfirmResponseBody,
} from '@models/purchase';
import { useAuthStore } from '@stores/authStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { usePaymentStore } from '@stores/paymentStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadPaymentWidget, PaymentWidgetInstance } from '@tosspayments/payment-widget-sdk';
import { PaymentMethodType, RequestPayParams, RequestPayResponse } from '@type/portone';
import {
  API_URL,
  ENABLED_PAYMENT_METHOD,
  IMP_MERCHANT_ID_CLASS,
  IMP_MERCHANT_ID_COMMERCE,
  IMP_PAYMENT_HOOK_PATH,
  TOSS_PAYMENTS_CLIENT_KEY,
  TOSS_PAYMENTS_SECRET_KEY,
} from '@variables';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAppSocket } from './appSocket';

export enum UpdatePaymentStatementEventType {
  CREATE = 'create',
  PAYMENT_DONE = 'payment_done',
  PAYMENT_CANCEL = 'payment_cancel',
  SETTLEMENT = 'settlement',
  REFUND = 'refund',
  SUBMISSION = 'submission',
  CANCEL_SUBMISSION = 'cancel_submission',
  RESERVE = 'reserve',
  REMOVE_RESERVE = 'remove_reserve',
  REPORT_CREATE = 'report_create',
  REPORT_UPDATE = 'report_update',
  REPORT_PUBLISH = 'report_publish',
}

export const usePayment = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const paymentStore = usePaymentStore();
  const appSocket = useAppSocket();
  const dialog = useOkCancelDialog();
  const tossPaymentWidgetRef = useRef<PaymentWidgetInstance>();
  const authStore = useAuthStore();
  const confirmParamsRef = useRef<TossCheckoutParams>();
  const queryClient = useQueryClient();

  const paymentStatementQuery = useQuery({
    queryKey: [`payment-statement-${paymentStore.paymentStatement?.uuid}`],
    queryFn: () => {
      if (!paymentStore.paymentStatement?.uuid) {
        return;
      }
      return commonApi.getPaymentStatement(paymentStore.paymentStatement.uuid);
    },
    enabled: !!paymentStore.paymentStatement?.uuid,
  });

  const updateTossPaymentsMutation = useMutation({
    mutationFn: commonApi.updateTossPayments,
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: [`payment-statement-${paymentStore.paymentStatement?.uuid}`] });
    },
  });

  const clear = () => {
    paymentStore.clear();
  };

  const onPaymentResult = (data: PaymentStatement, socket?: any) => {
    socket?.emit('payment_result_ack', {
      sender: { userId: authStore.user?.id, role: authStore.user?.role },
    });
    if ((data.statuses.payment || '').toLocaleLowerCase() === 'done') {
      paymentStore.setStatus('done');
    } else if ((data.statuses.payment || '').toLocaleLowerCase() === 'cancel') {
      paymentStore.setStatus('cancel');
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '결제 취소 알림',
        content: `결제하신 상품\n${data.product.name} ${data.purchases
          .map((item) => {
            if (item.name === 'discount') return undefined;
            return `${item.name}(${item.count})`;
          })
          .filter((item) => item)
          .join(
            ' / ',
          )}\n ${data.payment.billingAmount.toLocaleString()}원이 취소처리 되었습니다.\n마이페이지 > 결제내역에서 확인하실 수 있습니다.`,
      });
    } else if ((data.statuses.payment || '').toLocaleLowerCase() === 'waiting_for_deposit') {
      paymentStore.setStatus('ready');
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '계좌 이체 안내',
        content: '7일 이내에 입력하신 휴대폰 번호로 전송받으신 계좌로 이체해주셔야 결제가 완료됩니다.',
      });
    } else {
      paymentStore.setStatus('error');
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '결제에 실패했습니다.',
        content: data.payment?.result?.fail_reason || data.payment?.result?.failure?.message,
      });
    }
    paymentStore.setPaymentStatement(data);
  };

  useEffect(() => {
    appSocket.socket.addSocketEventListener('payment_result', 'payment_result', onPaymentResult);
    return () => {
      appSocket.socket.removeSocketEventListener('payment_result', 'payment_result');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentStatementQuery.data?.data) {
      paymentStore.setPaymentStatement(paymentStatementQuery.data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatementQuery.data?.data]);

  const renderTossPaymentsMethod = async (userId: string, billingAmount: number) => {
    const customerKey = userId;
    tossPaymentWidgetRef.current = await loadPaymentWidget(TOSS_PAYMENTS_CLIENT_KEY, customerKey); // 회원 결제
    // const paymentWidget = PaymentWidget(widgetClientKey, PaymentWidget.ANONYMOUS) // 비회원 결제
    const targetElement = document.getElementById('toss-payment-widget');
    if (targetElement) {
      tossPaymentWidgetRef.current.renderPaymentMethods('#toss-payment-widget', billingAmount, {
        variantKey: 'igogoWigetDetfault',
      });
    }
  };

  const payWithTossPayment = async (paymentStatement: PaymentStatement) => {
    const productId = location.state?.productId || searchParams.get('productId');
    const parentId = location.state?.parentId || searchParams.get('parentId');
    const tutorId = location.state?.tutorId || searchParams.get('tutorId');

    let redirectUrl = window.location.href;
    if (productId && parentId && tutorId) {
      const search = new URLSearchParams();
      search.set('productId', productId);
      search.set('parentId', parentId);
      search.set('tutorId', tutorId);

      redirectUrl = window.location.origin + window.location.pathname + '?' + search;
    }

    paymentStatement.statuses.payment = PaymentStatementPaymentStatus.IN_PROGRESS;
    paymentStore.setPaymentStatement(paymentStatement);

    const optionPrefix = paymentStatement.purchases[0].fee === 0 ? paymentStatement.purchases[0].name : '';
    if (tossPaymentWidgetRef.current) {
      const requestData = {
        orderId: paymentStatement.uuid,
        orderName: `${paymentStatement.product.name} ${paymentStatement.purchases
          .map((item) => {
            if (!item.fee) {
              return undefined;
            }
            return `${optionPrefix} ${item.name}(${item.count})`;
          })
          .filter((item) => item)
          .join(' / ')}`.slice(0, 80),
        successUrl: redirectUrl,
        failUrl: redirectUrl,
        customerEmail: authStore.user?.email,
        customerName: authStore.user?.profile?.name,
        customerMobilePhone: authStore.user?.profile?.phone,
      };

      if (!requestData.orderName) {
        requestData.orderName = paymentStatement.uuid;
      }

      tossPaymentWidgetRef.current.requestPayment(requestData).catch((e: any) => {
        if (e?.code === 'USER_CANCEL') {
          paymentStore.setStatus('cancel');
          dialog.open({
            type: 'ok',
            confirmColor: '#FF3D8F',
            title: '결제 알림',
            content: '결제를 취소했습니다.',
          });
          paymentStatement.statuses.payment = PaymentStatementPaymentStatus.CANCEL;
          paymentStatementUpdateMutation.mutate({
            data: paymentStatement,
            event: UpdatePaymentStatementEventType.PAYMENT_CANCEL,
          });
        } else {
          let message = `결제 진행 중 오류가 발생했습니다. ${JSON.stringify(e)}`;
          if (e.code === 'NEED_CARD_PAYMENT_DETAIL') {
            message = '카드사를 선택해주세요.';
          }
          dialog.open({
            type: 'ok',
            confirmColor: '#FF3D8F',
            title: '결제 오류',
            content: message,
          });
          paymentStatement.statuses.payment = PaymentStatementPaymentStatus.ERROR;
          paymentStatement.payment.error = e;
          paymentStatementUpdateMutation.mutate({
            data: paymentStatement,
            event: UpdatePaymentStatementEventType.PAYMENT_CANCEL,
          });
        }
      });
    } else {
      dialog.open({
        type: 'ok',
        confirmColor: '#FF3D8F',
        title: '결제 오류',
        content: '결제를 요청할 수 없습니다.',
      });
      paymentStatement.statuses.payment = PaymentStatementPaymentStatus.ERROR;
      paymentStatement.payment.error = { message: 'not found toss payment widget' };
      paymentStatementUpdateMutation.mutate({
        data: paymentStatement,
        event: UpdatePaymentStatementEventType.PAYMENT_CANCEL,
      });
    }
  };

  const confirmTossPayment = (params: TossCheckoutParams) => {
    if (
      (params.orderId && confirmParamsRef.current?.orderId !== params.orderId) ||
      (!params.orderId && params.message)
    ) {
      confirmParamsRef.current = params;

      const { orderId, paymentKey, amount, message } = params;

      if (message) {
        dialog.open({
          type: 'ok',
          confirmColor: '#FF3D8F',
          title: `결제에 실패했습니다.`,
          content: message,
          onConfirm: () => {
            window.location.reload();
          },
        });
        paymentStore.setStatus('error');
        if (orderId) {
          commonApi.getPaymentStatement(orderId).then((response) => {
            const paymentStatement = response.data;
            paymentStatement.statuses.payment = PaymentStatementPaymentStatus.ERROR;
            paymentStatement.payment.error = { message: 'not found toss payment widget' };
            paymentStatementUpdateMutation.mutate({
              data: paymentStatement,
              event: UpdatePaymentStatementEventType.PAYMENT_CANCEL,
            });
          });
        }
      } else if (paymentKey && amount && orderId) {
        axios
          .post(
            'https://api.tosspayments.com/v1/payments/confirm',
            { orderId, paymentKey, amount },
            { headers: { Authorization: `Basic ${TOSS_PAYMENTS_SECRET_KEY}`, 'Content-Type': 'application/json' } },
          )
          .then((response) => {
            const tossPaymentConfirmBody: TossPaymentsConfirmResponseBody = {
              createdAt: new Date().toString(),
              eventType: 'CONFIRM_RESULT_FROM_IGOGO_FRONT',
              data: response.data,
            };
            updateTossPaymentsMutation.mutate(tossPaymentConfirmBody);
          })
          .catch((e) => {
            dialog.open({
              type: 'ok',
              confirmColor: '#FF3D8F',
              title: `결제에 실패했습니다.`,
              content: `${e?.response?.data?.message}`,
            });
            paymentStore.setStatus(PaymentStatementPaymentStatus.ERROR);
            commonApi.getPaymentStatement(orderId).then((response) => {
              const paymentStatement = response.data;
              paymentStatement.statuses.payment = PaymentStatementPaymentStatus.ERROR;
              paymentStatement.payment.error = e;
              paymentStatementUpdateMutation.mutate({
                data: paymentStatement,
                event: UpdatePaymentStatementEventType.PAYMENT_CANCEL,
              });
            });
          });
      }
    }
  };

  const payWithIamport = async (paymentStatement: PaymentStatement) => {
    const productId = location.state?.productId || searchParams.get('productId');
    const parentId = location.state?.parentId || searchParams.get('parentId');
    const tutorId = location.state?.tutorId || searchParams.get('tutorId');

    let redirectUrl = window.location.href;
    if (productId && parentId && tutorId) {
      const search = new URLSearchParams();
      search.set('productId', productId);
      search.set('parentId', parentId);
      search.set('tutorId', tutorId);

      redirectUrl = window.location.origin + window.location.pathname + '?' + search;
    }

    paymentStore.setPaymentStatement(paymentStatement);

    const iamportRequestData: RequestPayParams = {
      pg: paymentStatement.payment.gateway,
      pay_method: paymentStatement.payment.method as PaymentMethodType,
      name: paymentStatement.product.name,
      amount: paymentStatement.payment.billingAmount,
      buyer_name: paymentStatement.buyer.name, // 구매자 이름
      buyer_tel: paymentStatement.buyer.phone, // 구매자 연락처
      buyer_email: '',
      buyer_addr: '',
      niceMobileV2: true,
      merchant_uid: paymentStatement.uuid,
      buyer_postcode: '06018',
      app_scheme: 'igogopayment',
      m_redirect_url: redirectUrl,
      notice_url: `${API_URL}${IMP_PAYMENT_HOOK_PATH}`,
    };

    if (paymentStatement.payment.method === 'naverpay') {
      let naverChainId = 'd2xqSzVzc2xPa3F'; // 비실물 원데이 기본
      if (paymentStatement.purchases.length > 1) {
        naverChainId = 'ODJZd1NrWXV6RTJ';
      }

      if (paymentStatement.shipping) {
        naverChainId = 'MUdsWmQ4S2JjZkQ';
      }

      const categoryType = paymentStatement.shipping ? 'PRODUCT' : 'ETC';
      const categoryId = paymentStatement.shipping ? 'GENERAL' : 'ETC';
      const naverProducts = paymentStatement.purchases.map((item) => {
        return {
          uid: paymentStatement.product.id,
          categoryId,
          categoryType,
          name: item.name,
          count: item.count,
        };
      });

      iamportRequestData['naverChainId'] = naverChainId;
      iamportRequestData['naverProducts'] = naverProducts;
      iamportRequestData['naverUseCfm'] = moment().format('YYYYMMDD');
    } else {
      if (paymentStatement.product.saleType === 'commerce' && IMP_MERCHANT_ID_COMMERCE) {
        iamportRequestData['pg'] = `${paymentStatement.payment.gateway}.${IMP_MERCHANT_ID_COMMERCE}`;
      } else if (paymentStatement.product.saleType === 'class' && IMP_MERCHANT_ID_CLASS) {
        iamportRequestData['pg'] = `${paymentStatement.payment.gateway}.${IMP_MERCHANT_ID_CLASS}`;
      }
    }

    iamportRequestData['custom_data'] = paymentStatement;

    const { IMP } = window;
    paymentStore.setPaymentStatement({
      ...paymentStatement,
      statuses: { ...paymentStatement.statuses, payment: PaymentStatementPaymentStatus.IN_PROGRESS },
    });

    if (IMP) {
      IMP.request_pay(iamportRequestData, (response: RequestPayResponse) => {
        const { success, error_msg } = response;

        if (!success) {
          paymentStore.setStatus(PaymentStatementPaymentStatus.CANCEL);
          dialog.open({ type: 'ok', confirmColor: '#FF3D8F', title: '결제에 실패했습니다.', content: error_msg });
          paymentStatement.statuses.payment = PaymentStatementPaymentStatus.CANCEL;
          paymentStatementUpdateMutation.mutate({
            data: paymentStatement,
            event: UpdatePaymentStatementEventType.PAYMENT_CANCEL,
          });
        }
      });
    }
  };

  const createPaymentStatementMutation = useMutation({
    mutationFn: commonApi.createPaymentStatement,
    onSuccess: async (response) => {
      if (response.data.error) {
        dialog.open({
          type: 'ok',
          confirmColor: '#FF3D8F',
          title: '결제에 실패했습니다.',
          content: '구매하실 수 있는 재고가 부족합니다.',
          onConfirm: () => {
            queryClient.invalidateQueries({ queryKey: [`product-item-${response.data?.error?.productId}`] });
            window.location.reload();
          },
        });
      } else {
        const paymentStatement = response.data;
        if (paymentStatement.payment.billingAmount > 0) {
          if (ENABLED_PAYMENT_METHOD === 'toss') {
            payWithTossPayment(paymentStatement);
          } else {
            payWithIamport(paymentStatement);
          }
        }
      }
    },
  });

  const paymentStatementUpdateMutation = useMutation({
    mutationFn: commonApi.updatePaymentStatement,
    onSuccess: async (response) => {
      const paymentStatement = response.data;
      paymentStore.setPaymentStatement(paymentStatement);
    },
  });

  return {
    requestPayment: createPaymentStatementMutation.mutate,
    renderTossPaymentsMethod: renderTossPaymentsMethod,
    confirmTossPayment: confirmTossPayment,
    status: paymentStore.status,
    setStatus: paymentStore.setStatus,
    paymentStatement: paymentStore.paymentStatement,
    clear: clear,
  };
};
