import React, { useState, useEffect, useCallback, useRef, MouseEvent } from 'react';
import SlideItem from './SlideItem';

const IndicatorCircle: React.FC<{ active?: boolean }> = (props) => {
  const active = props.active;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
      <circle cx="4" cy="4" r="4" fill="white" fillOpacity={active ? 1 : 0.2} />
    </svg>
  );
};
interface Image {
  id: string;
  path: string;
}

interface ImageSliderProps {
  images: Image[];
  width?: number;
  height?: number;
  autoRotateInterval?: number;
  centerScale?: number;
  sideScale?: number;
  outerScale?: number;
  centerBlur?: number;
  sideBlur?: number;
  outerBlur?: number;
  onImageClick?: (image: Image) => void;
}

const ImageSlider: React.FC<ImageSliderProps> = ({
  images,
  width = 300,
  height = 400,
  autoRotateInterval = 10000,
  centerScale = 1,
  sideScale = 0.9,
  outerScale = 0.8,
  centerBlur = 0,
  sideBlur = 0,
  outerBlur = 2,
  onImageClick,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);
  const startX = useRef(0);

  const rotateSlider = useCallback(
    (direction: 'next' | 'prev') => {
      setCurrentIndex((prevIndex) => {
        if (direction === 'next') {
          return (prevIndex + 1) % images.length;
        } else {
          return (prevIndex - 1 + images.length) % images.length;
        }
      });
    },
    [images.length],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      rotateSlider('next');
    }, autoRotateInterval);

    return () => clearInterval(interval);
  }, [rotateSlider, autoRotateInterval]);

  const getSlideStyle = (index: number) => {
    const diff = (index - currentIndex + images.length) % images.length;
    let transform = '';
    let clipPath = '';
    let scale = 1;
    let blur = 0;

    switch (diff) {
      case 0:
        transform = 'translateZ(0)';
        clipPath = 'inset(0 0 0 0)';
        scale = centerScale;
        blur = centerBlur;
        break;
      case 1:
      case images.length - 1:
        transform = `translateX(${diff === 1 ? '256px' : '-256px'}) translateZ(-100px)`;
        clipPath = `inset(0 ${diff === 1 ? '0 0 0' : '0 0 0'})`;
        scale = sideScale;
        blur = sideBlur;
        break;
      // case 2:
      // case images.length - 2:
      //   transform = `translateX(${diff === 2 ? '150%' : '-150%'}) translateZ(-200px)`;
      //   clipPath = `inset(0 ${diff === 2 ? '0 0 50%' : '50% 0 0'})`;
      //   scale = outerScale;
      //   blur = outerBlur;
      //   break;
      default:
        transform = 'translateZ(-300px)';
        clipPath = 'inset(0 100% 0 100%)';
    }

    return { transform, clipPath, scale, blur };
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    isDragging.current = true;
    startX.current = e.clientX;
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grabbing';
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isDragging.current) return;
    const diff = startX.current - e.clientX;
    if (Math.abs(diff) > 50) {
      rotateSlider(diff > 0 ? 'next' : 'prev');
      isDragging.current = false;
    }
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
    }
  };

  const handleClick = (index: number, image: Image) => {
    setCurrentIndex(index);
    if (onImageClick) {
      onImageClick(image);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        perspective: '1000px',
        cursor: 'grab',
        width: width,
        height: height,
      }}
      ref={sliderRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}>
      <div
        style={{
          position: 'relative',
          transformStyle: 'preserve-3d',
          transition: 'transform 0.5s',
          width: width,
          height: height,
        }}>
        {images.map((image, index) => (
          <SlideItem
            key={`image-${image.id}-${index}`}
            image={image.path}
            width={width}
            height={height}
            onClick={() => handleClick(index, image)}
            {...getSlideStyle(index)}
          />
        ))}
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '-56px',
          height: '56px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
        }}>
        {images.map((img, index) => {
          return <IndicatorCircle key={`indicator-${index}`} active={index === currentIndex}></IndicatorCircle>;
        })}
      </div>
    </div>
  );
};

export default ImageSlider;
