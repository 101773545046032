import CloseIcon from '@assets/images/app/btn-close.png';
import AppleLoginIcon from '@assets/images/app/social-apple-btn.png';
import KakaoLoginIcon from '@assets/images/app/social-kakao-btn.png';
import NaverLoginIcon from '@assets/images/app/social-naver-btn.png';
import { useAppNavigation } from '@hooks/appNavigation';
import { useAuthentication } from '@hooks/authentication';
import { useAppLoginModal } from '@stores/loginModalStore';
import { getLastLoginType } from '@utils/string';
import { APP_BASE_PATH, KAKAO_LOGIN_REDIRECT_URL, NAVER_APP_ID, NAVER_LOGIN_REDIRECT_URL } from '@variables';
import { Tooltip } from 'react-tooltip';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import 'react-tooltip/dist/react-tooltip.css';

const LAST_LOGIN_TYPE_TITLE = '마지막 로그인';

export const AppLoginModal: React.FC = () => {
  const appNavigation = useAppNavigation();
  const { show, close, options } = useAppLoginModal();
  const { authStore, setRedirectUrl } = useAuthentication({ disableLoginRefresh: true });
  const location = useLocation();

  const loginKakao = () => {
    if (Kakao && Kakao.isInitialized()) {
      setRedirectUrl(location.pathname);
      Kakao.Auth.authorize({ redirectUri: KAKAO_LOGIN_REDIRECT_URL });
    }
  };

  const loginNaver = () => {
    setRedirectUrl(location.pathname);
    window.location.assign(
      `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_APP_ID}&state=${v4().replace(
        /-/g,
        '',
      )}&redirect_uri=${NAVER_LOGIN_REDIRECT_URL}`,
    );
  };

  const loginApple = async () => {
    try {
      setRedirectUrl(location.pathname);
      await AppleID.auth.signIn();
    } catch (error) {
      console.log('loginApple error', error);
    }
  };

  const showCondition = !authStore.user && show;
  const lastLoginType = getLastLoginType();

  return (
    <div
      onClick={() => {
        close();
        if (options?.closeAndGoBack) {
          appNavigation.back();
        }
      }}
      style={{
        transition: 'opacity 0.3s linear 0s',
        opacity: showCondition ? 1 : 0,
        position: 'absolute',
        width: showCondition ? '100%' : 0,
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.4)',
        zIndex: 99999,
        overflow: 'hidden',
      }}>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        style={{
          transition: 'all 0.3s linear 0s',
          position: 'absolute',
          opacity: showCondition ? 1 : 0,
          bottom: 0,
          paddingBottom: '52px',
          maxHeight: showCondition ? '360px' : 0,
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '8px 8px 0 0',
          overflow: 'hidden',
        }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', height: '56px' }}>
            <button
              className="link-button"
              style={{ padding: '12px', cursor: 'pointer' }}
              onClick={() => {
                close();
                if (options?.closeAndGoBack) {
                  appNavigation.back();
                }
              }}>
              <img style={{ width: '32px', height: '32px' }} src={CloseIcon} alt="" />
            </button>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <div
              style={{
                whiteSpace: 'pre-wrap',
                fontSize: '25px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              간편 로그인으로
              <br />
              시작하기
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '42px', justifyContent: 'center' }}>
          <Tooltip
            id="last-login-kakao"
            style={{ backgroundColor: '#FF3D8F', fontWeight: 'bold' }}
            content={LAST_LOGIN_TYPE_TITLE}
            isOpen={lastLoginType === 'kakao'}
            hidden={lastLoginType !== 'kakao'}></Tooltip>
          <Tooltip
            id="last-login-naver"
            style={{ backgroundColor: '#FF3D8F', fontWeight: 'bold' }}
            content={LAST_LOGIN_TYPE_TITLE}
            isOpen={lastLoginType === 'naver'}
            hidden={lastLoginType !== 'naver'}></Tooltip>
          <Tooltip
            id="last-login-apple"
            style={{ backgroundColor: '#FF3D8F', fontWeight: 'bold' }}
            content={LAST_LOGIN_TYPE_TITLE}
            isOpen={lastLoginType === 'apple'}
            hidden={lastLoginType !== 'apple'}></Tooltip>
          <Tooltip
            id="last-login-email"
            style={{ backgroundColor: '#FF3D8F', fontWeight: 'bold' }}
            content={LAST_LOGIN_TYPE_TITLE}
            isOpen={lastLoginType === 'email'}
            hidden={lastLoginType !== 'email'}></Tooltip>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <button
              data-tooltip-id="last-login-kakao"
              className="link-button"
              style={{
                display: 'flex',
                width: '76px',
                height: '52px',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              onClick={() => {
                loginKakao();
              }}>
              <img style={{ width: '52px', height: '52px' }} src={KakaoLoginIcon} alt="" />
            </button>
            <button
              data-tooltip-id="last-login-naver"
              className="link-button"
              style={{
                display: 'flex',
                width: '76px',
                height: '52px',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              onClick={() => {
                loginNaver();
              }}>
              <img style={{ width: '52px', height: '52px' }} src={NaverLoginIcon} alt="" />
            </button>
            <button
              data-tooltip-id="last-login-apple"
              className="link-button"
              style={{
                display: 'flex',
                width: '76px',
                height: '52px',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
              onClick={() => {
                loginApple();
              }}>
              <img style={{ width: '52px', height: '52px' }} src={AppleLoginIcon} alt="" />
            </button>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: '32px', justifyContent: 'center' }}>
            <button
              data-tooltip-id="last-login-email"
              className="link-button"
              style={{ textDecorationLine: 'underline', color: '#808387', fontSize: '14px' }}
              onClick={() => {
                close();
                appNavigation.to(`${APP_BASE_PATH}login/email`);
              }}>
              기존 이메일 로그인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLoginModal;
