import React from 'react';
import ButtonMinusIcon from '@assets/images/app/button-minus-on.png';
import ButtonPlusIcon from '@assets/images/app/button-plus-on.png';
import ButtonMinusDisabledIcon from '@assets/images/app/button-minus-disable.png';
import ButtonPlusDisabledIcon from '@assets/images/app/icon-plus-disabled.png';

export interface NumberEditProps {
  onClickMinus?: (value: number) => void;
  onClickPlus?: (value: number) => void;
  onChangeValue?: (value: number) => void;
  min?: number;
  max?: number;
  value?: number;
  unit?: string;
  unitSize?: number;
  disabled?: boolean;
}

export const NumberEdit: React.FC<NumberEditProps> = (props) => {
  return (
    <div
      style={{
        height: '36px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      }}>
      <button
        className="link-button"
        disabled={props.disabled}
        onClick={() => {
          let result = (props?.value || 0) - (props?.unitSize || 1);
          if (typeof props.min === 'number' && result < props.min) {
            result = props.min;
          } else if (typeof props.max === 'number' && result > props.max) {
            result = props.max;
          }
          props.onClickMinus && props.onClickMinus(result);
        }}>
        <div
          style={{
            display: 'flex',
            width: '36px',
            height: '36px',
            border: '1px solid #d8d8d8',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img
            style={{ width: '20px', height: '20px' }}
            src={props.disabled ? ButtonMinusDisabledIcon : ButtonMinusIcon}
            alt=""
          />
        </div>
      </button>
      <div>
        <div
          style={{
            height: '36px',
            borderTop: '1px solid #d8d8d8',
            borderBottom: '1px solid #d8d8d8',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <input
            disabled={props.disabled}
            style={{
              outline: 'none',
              border: 'none',
              fontSize: '16px',
              color: '#373737',
              textAlign: 'center',
              marginLeft: '10px',
              marginRight: '4px',
              paddingBottom: 0,
              paddingTop: 0,
              width: '60px',
            }}
            value={props.value || 0}
            onChange={(e) => {
              let result = Number(e.target.value);
              if (typeof props.min === 'number' && result < props.min) {
                result = props.min;
              } else if (typeof props.max === 'number' && result > props.max) {
                result = props.max;
              }

              props.onChangeValue && props.onChangeValue(Number(e.target.value));
            }}
            type="number"
          />
          {props.unit && (
            <span
              style={{
                fontSize: '16px',
                color: '#9b9b9b',
                alignSelf: 'center',
                textAlign: 'left',
                justifyContent: 'center',
                paddingRight: '8px',
              }}>
              {props.unit}
            </span>
          )}
        </div>
      </div>
      <button
        disabled={props.disabled}
        className="link-button"
        onClick={() => {
          let result = (props?.value || 0) + (props?.unitSize || 1);
          if (typeof props.min === 'number' && result < props.min) {
            result = props.min;
          } else if (typeof props.max === 'number' && result > props.max) {
            result = props.max;
          }
          props.onClickPlus && props.onClickPlus(result);
        }}>
        <div
          style={{
            display: 'flex',
            width: '36px',
            height: '36px',
            border: '1px solid #d8d8d8',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img
            style={{ width: '20px', height: '20px' }}
            src={props.disabled ? ButtonPlusDisabledIcon : ButtonPlusIcon}
            alt=""
          />
        </div>
      </button>
    </div>
  );
};
