import { useAuthStore } from '@stores/authStore';
import { useEffect } from 'react';
import { useAppNavigation } from './appNavigation';
import { AppTokenPayload, useAppSocket } from './appSocket';
import { DeeplinkAction, DeeplinkActionPayload, DeepLinkActions, useDeeplink } from './deeplink';
import { useAuthentication } from './authentication';
import { LoginType } from '@models/auth';
import { getNativeAppInfoFromUserAgent, sendMessageToNative } from '@utils/common';

export interface Message {
  type: MessageActions;
  data?: MessageDataForm;
  payload?: NotificationData;
}

export interface MessageDataForm {
  userId?: string;
  email?: string;
  loginType?: LoginType;
  deviceId?: string;
  fcmToken?: string;
  iosOriginToken?: string;
  url?: string;
}

export interface LegacyLoginInfo {
  userId: string;
  email?: string;
  loginType?: LoginType;
}

export enum MessageActions {
  UPDATE_TOKEN = 'update_token',
  // MOVE_FROM_NOTIFICATION = 'move_from_notification',
  MOVE_FROM_NOTIFICATION = 'notification',
  BACK_TO_PREVIOUS = 'back_to_previous',
  SHARE_URL = 'share_url',
  GET_DEVICEID = 'get_deviceId',
  CLOSE_MODAL = 'close_modal',
  GET_ASYNC_STORAGE_LOGIN_USERINFO = 'get_async_storage_login_userinfo',
  DONE_LEGACY_LOGIN = 'done_legacy_login',
  LOGOUT = 'logout',
  LOGIN = 'login',
  DENY_PARENT_ETC_PUSH = 'deny_parent_etc_push',
  ACCPET_PARENT_ETC_PUSH = 'accept_parent_etc_push',
  DENY_TUTOR_ETC_PUSH = 'deny_tutor_etc_push',
  ACCPET_TUTOR_ETC_PUSH = 'accept_tutor_etc_push',
  DENY_ETC_PUSH = 'deny_etc_push',
  POP_STACK = 'pop_stack',
  POP_STACK_SWIPE_GESTURE = 'pop_stack_swipe_gesture',
  PUSH_STACK = 'push_stack',
  GET_USER_INFO = 'get_user_info',
  MOVE_FROM_DEEPLINK = 'move_from_deeplink',
  DOWNLOAD_IMAGES = 'download_images',
  SEND_INAPP_BROWSER = 'send_inapp_browser',
}
export interface Child {
  name: string;
  birthday: string;
  gender: string;
}

export class FcmPayloadData {
  notificationId?: string;
  tutorId?: string;
  parentId?: string;
  classId?: string;
  tutorName?: string;
  parentName?: string;
  className?: string;
  classReportId?: string;
  classInvoiceId?: string;
  classImageUrl?: string;
  isTutor?: boolean;
  childrenName?: string;
  isChatToReport?: boolean;
  isClassCardModal?: boolean;
  children?: Child[];
  scheduleStartDate?: Date;
  scheduleTimes?: number;
}
export interface NotificationData {
  title?: string;
  body?: string;
  action?: DeepLinkActions;
  payload?: FcmPayloadData;
}

const getDeepLinkActionDataFromUrl = (url: string): { action: DeepLinkActions; payload: DeeplinkActionPayload } => {
  const appScheme = 'igogo://';
  const legacyAppScheme = 'igogoapp://';

  const path = url
    .replace('http//', 'http://')
    .replace('https//', 'https://')
    .replace(appScheme, '')
    .replace(legacyAppScheme, '')
    .replace(`${document.location.origin}/`, '')
    .split('?');
  const route = path[0].split('/')[0];
  const params = path[0].split('/')[1];
  const queryString = path[1];

  let action = DeepLinkActions.GO_TO_MAIN;
  const payload = {} as DeeplinkActionPayload;
  if (route === 'product') {
    action = DeepLinkActions.GO_TO_PRODUCT;
    payload['productId'] = params;
  } else if (route === 'collection') {
    action = DeepLinkActions.GO_TO_COLLECTION;
    payload['collectionId'] = params;
  } else if (route === 'notice') {
    action = DeepLinkActions.GO_TO_NOTICE;
    payload['noticeId'] = params;
  } else if (route === 'chat') {
    action = DeepLinkActions.GO_TO_CHAT;
    if (queryString) {
      const chatParams = queryString.split('&');
      chatParams.forEach((chatParam: string) => {
        const param = chatParam.split('=');
        const key = param[0] as 'productId' | 'tutorId' | 'parentId' | 'chatRoomId';
        payload[key] = param[1];
      });
    }
  } else if (route === 'commerce' || route === 'pick') {
    action = DeepLinkActions.GO_TO_PICK;
  } else if (route === 'my-page') {
    if (params === 'purchase') {
      action = DeepLinkActions.GO_TO_PURCHASE;
    } else if (params === 'reward') {
      action = DeepLinkActions.GO_TO_REWARD;
    } else {
      action = DeepLinkActions.GO_TO_MY_PAGE;
    }
  }
  return { action, payload };
};

export const useAppMessage = () => {
  const appSocket = useAppSocket();
  const authStore = useAuthStore();
  const { linkTo } = useDeeplink();
  const appNavigation = useAppNavigation();
  const { loginLegacy } = useAuthentication();

  useEffect(() => {
    window.addEventListener('message', handleEvent);
    document.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
      document.removeEventListener('message', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.user]);

  const handleEvent = (event: any) => {
    const isStringifiedData = typeof event.data === 'string';
    let eventData: Message = {} as Message;

    if (event.data.constructor === 'Object') {
      eventData = event.data;
    } else {
      try {
        eventData = JSON.parse(event.data);
        // eslint-disable-next-line no-empty
      } catch {}
    }

    if (isStringifiedData) {
      try {
        const { isNativeApp } = getNativeAppInfoFromUserAgent();
        const type: MessageActions = eventData.type;
        const data = eventData.data || {};
        if (type) {
          if (type === MessageActions.GET_ASYNC_STORAGE_LOGIN_USERINFO) {
            if (isNativeApp) {
              const legacyLoginInfo: LegacyLoginInfo = {
                userId: data?.userId || '',
                email: data.email,
                loginType: data.loginType,
              };
              loginLegacy(legacyLoginInfo);
            }
          } else if (type === MessageActions.UPDATE_TOKEN) {
            const appTokenForm: AppTokenPayload = {
              deviceId: data?.deviceId || '',
              fcmToken: data?.fcmToken || '',
              iosOriginToken: data?.iosOriginToken,
              userId: authStore.user?.id,
              lastLoginType: authStore.user?.loginType,
            };
            appSocket.updateAppToken(appTokenForm);
          } else if (type === MessageActions.MOVE_FROM_NOTIFICATION) {
            const notification: NotificationData = { ...eventData.payload };
            const notificationId = notification.payload?.notificationId || '';
            const deeplinkData: DeeplinkAction = {
              action: notification?.action,
              payload: notification?.payload,
            };
            if (notificationId) {
              appSocket.increasePushNotificationClickCount(notificationId);
            }
            linkTo(deeplinkData, { source: 'push-notification', notificationId: notificationId });
          } else if (type === MessageActions.BACK_TO_PREVIOUS) {
            appNavigation.back();
          } else if (type === MessageActions.POP_STACK_SWIPE_GESTURE) {
            appNavigation.popStack();
          } else if (type === MessageActions.GET_USER_INFO) {
            if (isNativeApp) {
              const response = {
                type: MessageActions.GET_USER_INFO,
                data: {
                  id: authStore?.user?.id,
                  loginType: authStore?.user?.loginType,
                  role: authStore?.user?.role,
                  otherAlarmAgree: authStore?.user?.profile?.otherAlarmAgree,
                },
              };
              sendMessageToNative(response);
            }
          } else if (type === MessageActions.MOVE_FROM_DEEPLINK) {
            const deeplinkUrl = data.url;
            if (deeplinkUrl) {
              const { action, payload } = getDeepLinkActionDataFromUrl(deeplinkUrl);
              const deeplinkData: DeeplinkAction = {
                action: action,
                payload: payload,
              };
              linkTo(deeplinkData, { source: 'deep-link', link: deeplinkUrl });
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  };
};
