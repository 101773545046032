import React, { useEffect, useState } from 'react';

interface CheckBoxProps {
  children?: any;
  checked?: boolean;
  onChecked?: (value: boolean) => void;
}

export const CheckBox: React.FC<CheckBoxProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const newValue = !checked;
        setChecked(newValue);
        props.onChecked && props.onChecked(newValue);
      }}>
      <svg
        style={{
          borderRadius: '4px',
          width: '24px',
          height: '24px',
          border: checked ? 'none' : '1px solid #E8EAED',
          backgroundColor: checked ? '#FF3D8F' : 'white',
        }}
        width="24px"
        height="24px"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Icon/Outline/check">
          <path
            id="Icon"
            d="M3.33301 8.66666L5.99967 11.3333L12.6663 4.66666"
            stroke={checked ? 'white' : '#D5D8DD'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
      <div
        style={{
          marginLeft: props.children ? '12px' : 0,
          color: 'var(--Neutral-90, #424242)',
          fontFamily: 'Pretendard',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
        }}>
        {props.children}
      </div>
    </div>
  );
};

export default CheckBox;
