import React from 'react';

export const ChevronDownIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/chevron-down">
        <path
          id="Icon"
          d="M19 9L12 16L5 9"
          stroke={props.color || '#424242'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const ChevronUpIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/chevron-up">
        <path
          id="Icon"
          d="M5 15L12 8L19 15"
          stroke={props.color || '#424242'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const NavBackIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/arrow-left-white">
        <path
          id="Icon"
          d="M11.6667 22.1673L3.5 14.0007M3.5 14.0007L11.6667 5.83398M3.5 14.0007L24.5 14.0007"
          stroke={props.color || 'white'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const CommerceIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Outline/store">
        <path
          id="Vector"
          d="M3.33333 10V16.6667C3.33333 17.1269 3.70643 17.5 4.16667 17.5H15.8333C16.2936 17.5 16.6667 17.1269 16.6667 16.6667V10M16.6667 5.83333V3.61111C16.6667 2.99746 16.1692 2.5 15.5556 2.5H4.44444C3.83079 2.5 3.33333 2.99746 3.33333 3.61111V5.83333H16.6667ZM11.6667 17.5V15.8333C11.6667 14.9129 10.9205 14.1667 10 14.1667C9.07952 14.1667 8.33333 14.9129 8.33333 15.8333V17.5H11.6667ZM2.5 5.83333V8.33333C2.5 9.71404 3.61929 10.8333 5 10.8333C6.38071 10.8333 7.5 9.71404 7.5 8.33333C7.5 9.71404 8.61929 10.8333 10 10.8333C11.3807 10.8333 12.5 9.71404 12.5 8.33333C12.5 9.71404 13.6193 10.8333 15 10.8333C16.3807 10.8333 17.5 9.71404 17.5 8.33333V5.83333H2.5Z"
          stroke="#808387"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const ClassIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Outline/academic-cap">
        <path
          id="Icon"
          d="M10.0001 11.8491L18.3334 7.21947L10.0001 2.58984L1.66675 7.21947L10.0001 11.8491ZM10.0001 11.8491L15.703 8.68082C16.2054 9.9478 16.4816 11.3291 16.4816 12.775C16.4816 13.4243 16.4259 14.0607 16.319 14.6795C13.9022 14.9145 11.7122 15.9238 10.0001 17.4561C8.28801 15.9238 6.09796 14.9145 3.68121 14.6795C3.57431 14.0607 3.5186 13.4243 3.5186 12.7749C3.5186 11.3291 3.79475 9.94777 4.29719 8.68081L10.0001 11.8491ZM6.29638 17.4046V10.4602L10.0001 8.4026"
          stroke="#808387"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const HomeIcon: React.FC<{ color?: string; style?: any }> = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <g id="Icon/Outline/home-white">
        <path
          d="M2.625 13.9996L13.0713 3.55209C13.5847 3.03993 14.4153 3.03993 14.9275 3.55209L25.375 13.9996M5.25 11.3746V23.1871C5.25 23.9116 5.838 24.4996 6.5625 24.4996H11.375V18.8121C11.375 18.0876 11.963 17.4996 12.6875 17.4996H15.3125C16.037 17.4996 16.625 18.0876 16.625 18.8121V24.4996H21.4375C22.162 24.4996 22.75 23.9116 22.75 23.1871V11.3746M9.625 24.4996H19.25"
          stroke={props.color || 'white'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
