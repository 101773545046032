import { useAppBottomSheetStore } from '@stores/appBottomSheet';
import { useAppMarketingModalStore } from '@stores/appMarketingModalStore';
import { useAppNavigationStore } from '@stores/appNavigationStore';
import { useAppTermsModal } from '@stores/appTermsModalStore';
import { useAppUserSessionStore } from '@stores/appUserSessionStore';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';
import { useAppLoginModal } from '@stores/loginModalStore';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { useReviewModalStore } from '@stores/reviewModalStore';
import { sendMessageToNative, getNativeAppInfoFromUserAgent } from '@utils/common';
import { useEffect, useState } from 'react';
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { MessageActions } from './appMessage';
import { APP_BASE_PATH } from '@variables';
import { useAuthStore } from '@stores/authStore';

export const useAppNavigation = () => {
  const [target, setTarget] = useState<{ path?: string; state?: any; back?: boolean }>({});
  const sessionStore = useAppUserSessionStore();
  const loginModal = useAppLoginModal();
  const classInvoiceModal = useClassInvoiceModalStore();
  const dialog = useOkCancelDialog();
  const appBottomSheet = useAppBottomSheetStore();
  const appTerms = useAppTermsModal();
  const reviewModal = useReviewModalStore();
  const marketingModal = useAppMarketingModalStore();
  const store = useAppNavigationStore();
  const navigate = useNavigate();
  const location = useLocation();
  const authStore = useAuthStore();
  const { isNativeApp } = getNativeAppInfoFromUserAgent();

  useEffect(() => {
    if (location.pathname === '' || location.pathname === `${APP_BASE_PATH}`) {
      store.clear();
    }
    store.push({ path: location.pathname === '' ? `${APP_BASE_PATH}` : location.pathname, state: location.state });

    store.setHistoryState(window.history.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (target.path) {
      navigate(target.path, { state: target.state, replace: target.back });
      setTarget({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.path]);

  const to = (path: string, options?: NavigateOptions, back?: boolean) => {
    sessionStore.setActionData({
      from: location.pathname,
      to: path,
      state: options?.state,
      actionType: back ? 'back' : 'to',
    });

    const { appVersion } = getNativeAppInfoFromUserAgent();
    if (isNativeApp && appVersion) {
      const authRoutes = [
        '/auth/kakao',
        '/auth/naver',
        '/auth/apple',
        '/auth/v1/login/apple/callback',
        '/login/email',
        '/join',
        '/my-page/settings',
      ];
      const isAuthNavigate = authRoutes.includes(path) || (location.pathname && authRoutes.includes(location.pathname));
      const mainRoutes = [`/`, `/search`, `/chats`, `/commerce`, `/my-page`, '/events'];
      const isFromMain = mainRoutes.includes(location.pathname);
      const isToMain = mainRoutes.includes(path);
      const navigateToSubView = isFromMain && !isToMain;
      const navigateToMainView = !isFromMain && isToMain;
      const state = {
        ...options?.state,
        id: authStore?.user?.id,
        loginType: authStore?.user?.loginType,
        role: authStore?.user?.role,
      };
      if (isAuthNavigate) {
        setTarget({ path, state: options?.state, back: back });
      } else if (navigateToSubView) {
        const response = {
          type: MessageActions.PUSH_STACK,
          data: {
            path: path,
            state: state,
          },
        };
        sendMessageToNative(response);
      } else if (navigateToMainView) {
        const response = {
          type: MessageActions.POP_STACK,
          data: {
            path: path,
            state: state,
          },
        };
        sendMessageToNative(response);
      } else {
        setTarget({ path, state: options?.state, back: back });
      }
    } else {
      setTarget({ path, state: options?.state, back: back });
    }
  };

  const closeModal = () => {
    let closed = false;
    closed = closed || loginModal.close();
    closed = closed || classInvoiceModal.close();
    closed = closed || dialog.close();
    closed = closed || appBottomSheet.close();
    closed = closed || appTerms.close();
    closed = closed || reviewModal.close();
    closed = closed || (location.pathname === `${APP_BASE_PATH}` && marketingModal.close());
    return closed;
  };

  const back = (replace: boolean = true, force?: boolean) => {
    let data = store.pop();
    while (data.path !== `${APP_BASE_PATH}`) {
      if (
        data.path !== location.pathname &&
        !data.path?.includes('auth') &&
        !data.path?.includes('/my-page/user-profile/')
      ) {
        break;
      }
      data = store.pop();
    }
    const closed = closeModal();

    if (location.pathname === '/notfound' || data.path === '/notfound') {
      data.path = '/';
    }
    if ((force || !closed) && data.path) {
      to(data.path, { replace: replace, state: { ...data.state } }, true);
    }

    const response = {
      type: MessageActions.CLOSE_MODAL,
      data: {
        isModalClose: closed,
      },
    };
    sendMessageToNative(response);
  };

  const popStack = () => {
    const last = store.pop();
    if (last.path === location.pathname) {
      store.pop();
    }
  };

  return { to: to, back: back, popStack: popStack, update: store.update, store: store, closeModal };
};
