import { RemoteConfig } from '@models/remote-config';
import { useAppUpdateModal } from '@stores/appUpdateModalStore';
import { useQuery } from '@tanstack/react-query';
import { getNativeAppInfoFromUserAgent } from '@utils/common';
import { CDN_BASE_URL, STAGE } from '@variables';
import axios from 'axios';
import { useEffect, useRef } from 'react';

export const compareVersion = (currentVersion: string | undefined, targetVersion: string): boolean => {
  if (!currentVersion) {
    return true;
  }
  const parseVersion = (version: string) => {
    const [major = 0, minor = 0, patch = 0] = version.split('.').map((item) => Number(item));
    return { major, minor, patch };
  };
  const current = parseVersion(currentVersion);
  const target = parseVersion(targetVersion);
  if (target.major !== current.major) {
    return target.major - current.major > 0;
  }
  if (target.minor !== current.minor) {
    return target.minor - current.minor > 0;
  }
  return target.patch - current.patch > 0;
};

export const useAppPopup = () => {
  const store = useAppUpdateModal();
  const stage = STAGE === 'prod' ? STAGE : 'dev';
  const configDataUrl = `${CDN_BASE_URL}/config/${stage}.json`;
  const { isNativeApp, appVersion, appPlatform } = getNativeAppInfoFromUserAgent();
  let showUpdatePopup = false;
  let showMaintenancePopup = false;
  const isAppUpdateAvailable = useRef<boolean>(false);

  const getRemoteConfigQuery = useQuery({
    queryKey: ['remote-config', JSON.stringify(store.data)],
    queryFn: () => axios.get(configDataUrl),
  });
  const configData = getRemoteConfigQuery.data?.data as RemoteConfig;

  useEffect(() => {
    const configData = getRemoteConfigQuery.data?.data;
    if (configData) {
      store.setConfigData(configData);
      if (configData[`${appPlatform}`]?.target) {
        isAppUpdateAvailable.current = compareVersion(appVersion, configData[`${appPlatform}`].target);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRemoteConfigQuery.data?.data]);

  const isUpdated = JSON.stringify(configData) !== JSON.stringify(store.data);

  if (configData && isUpdated) {
    if (isNativeApp) {
      const platformConfig = configData[`${appPlatform!}`];
      if (platformConfig && platformConfig.active && platformConfig.target) {
        showUpdatePopup = compareVersion(appVersion, platformConfig.target);
      } else if (configData.maintenance && configData.maintenance.active) {
        showMaintenancePopup = true;
      }

      if (showUpdatePopup || showMaintenancePopup) {
        store.open({
          type: showMaintenancePopup ? 'maintenance' : platformConfig?.method,
          description: configData?.maintenance?.description,
          startDate: configData?.maintenance?.startDate,
          endDate: configData?.maintenance?.endDate,
        });
      } else {
        store.close();
      }
    } else {
      const maintenanceConfig = configData['maintenance'];
      if (maintenanceConfig && maintenanceConfig.active) {
        store.open({
          type: 'maintenance',
          description: maintenanceConfig?.description,
          startDate: maintenanceConfig?.startDate,
          endDate: maintenanceConfig?.endDate,
        });
      } else {
        store.close();
      }
    }
  }

  return { isAppUpdateAvailable: isAppUpdateAvailable.current };
};
