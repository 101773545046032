import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DateTimeButtonPicker from './DateTimeButtonPicker';
import CellTitle from './CellTitle';
import { ClassDates, ClassTimes } from '@models/invoice';
import { ClassSchedule } from '@models/schedule';

export interface ClassDateEditProps {
  times?: ClassTimes;
  dates?: ClassDates;
  schedules?: ClassSchedule[];
  onChange?: (value: ClassDates) => void;
  onChangeSchedule?: (schedules: ClassSchedule[]) => void;
}

export const ClassDateEdit: React.FC<ClassDateEditProps> = (props) => {
  const totalClassTimes = props.schedules ? (props.schedules || []).length : props.times?.totalClassTimes || 0;
  const [dates, setDates] = useState<Date[]>([]);

  useEffect(() => {
    if (props.schedules) {
      setDates(props.schedules.map((schedule) => new Date(schedule.startDate)));
    } else if (!dates) {
      setDates(new Array(totalClassTimes));
    } else {
      setDates((prev) => {
        const newArray = [...prev];
        newArray.length = totalClassTimes;
        return newArray;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalClassTimes]);

  useEffect(() => {
    const newDates = { dates, hasEmpty: [...dates].filter((value) => !value).length > 0 };
    if (JSON.stringify(props.dates) !== JSON.stringify(newDates)) {
      props.onChange && props.onChange(newDates);
      const schedules = (props.schedules || []).map((schedule, index) => {
        return {
          ...schedule,
          startDate: dates[index] ? dates[index].toISOString() : schedule.startDate,
        };
      });

      props.onChangeSchedule && props.onChangeSchedule(schedules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  return (
    <div style={{ padding: '28px 20px' }}>
      <CellTitle>수강 일정</CellTitle>
      <div
        style={{
          color: 'var(--Neutral-60, #9B9DA0)',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          marginTop: '6px',
          marginBottom: '24px',
        }}>
        선택한 수강 회차에 따라 일정 목록이 생성됩니다.
      </div>
      {[...dates].map((date, index) => {
        return (
          <div style={{ marginTop: '16px' }}>
            <div
              style={{
                color: 'var(--Neutral-90, #424242)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '22px',
              }}>{`${index + 1}회차 일정`}</div>
            <DateTimeButtonPicker
              value={date}
              minDate={moment().format('YYYY-MM-DD')}
              minTime={'07:00'}
              maxTime={'21:00'}
              onChange={(value) => {
                setDates((prev) => {
                  const newValue = [...prev];
                  newValue[index] = value;
                  return newValue;
                });
              }}></DateTimeButtonPicker>
          </div>
        );
      })}
      <div
        style={{
          width: '100%',
          height: '64px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px 16px',
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          borderRadius: '8px',
          backgroundColor: '#E6F7FF',
          color: 'var(--Blue-60, #1890FF)',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          marginTop: '24px',
        }}>
        {'모든 수강 일정 완료 후 리포트를 작성하시면,\n수강료 정산 신청이 완료됩니다.'}
      </div>
    </div>
  );
};

export default ClassDateEdit;
