import * as userApi from '@apis/app-user';
import * as api from '@apis/auth';
import { User } from '@models/auth';
import { LoginStatus, useAuthStore } from '@stores/authStore';
import { useAppLoginModal } from '@stores/loginModalStore';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getNativeAppInfoFromUserAgent, sendMessageToNative, setPushAlarmAgree } from '@utils/common';
import { getAfterLoginRedirectUrl, setAfterLoginRedirectUrl, setLastLoginType } from '@utils/string';
import { APP_BASE_PATH } from '@variables';
import md5 from 'md5';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { MessageActions } from './appMessage';
import { useAppNavigation } from './appNavigation';
import { useAppSocket } from './appSocket';
import Cookies from 'js-cookie';
import { useOkCancelDialog } from '@stores/okCancelDialogStore';
import { Roles } from '@consts/role';

export const useAuthentication = (options?: {
  seller?: boolean;
  tutor?: boolean;
  loginRequired?: boolean;
  closeAndGoBack?: boolean;
  disableLoginRefresh?: boolean;
}) => {
  const appLoginModal = useAppLoginModal();
  const appNavigation = useAppNavigation();
  const [errorResponse, setErrorResponse] = useState<any>();
  const appSocket = useAppSocket();
  const authStore = useAuthStore();
  const { isNativeApp } = getNativeAppInfoFromUserAgent();
  const needRefresh = useRef<boolean>(true);
  const dialog = useOkCancelDialog();

  const redirect = () => {
    const redirectUrl = getAfterLoginRedirectUrl();
    appNavigation.to(redirectUrl || `${APP_BASE_PATH}`, { replace: true });
  };

  const setUser = (user?: User) => {
    const savedUser = md5(JSON.stringify(authStore.user || {}));
    const newUser = md5(JSON.stringify(user || {}));

    if (savedUser !== newUser) {
      if (user) {
        appSocket.sendEvent({ type: 'login', data: user });
        setLastLoginType(user.loginType);
        if (isNativeApp) {
          const message = {
            type: MessageActions.LOGIN,
            data: {
              id: user?.id,
              loginType: user?.loginType,
              role: user?.role,
              otherAlarmAgree: user?.profile?.otherAlarmAgree,
            },
          };
          sendMessageToNative(message);
        }
      } else {
        appSocket.sendEvent({ type: 'logout', data: authStore.user });
        if (isNativeApp) {
          const message = {
            type: MessageActions.LOGOUT,
            data: {},
          };
          sendMessageToNative(message);
        }
      }
      authStore.setUser(user);
    }
  };

  const likeListQuery = useQuery({
    queryKey: [`user-favorite-${authStore.user?.id}`],
    queryFn: () => userApi.getFavorite(),
    enabled: false,
  });

  const showLoginErrorToast = () => {
    setTimeout(() => {
      toast.dark('탈퇴한 사용자이거나 일치하는 사용자 정보가 없습니다.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        bodyStyle: {
          color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
        },
        theme: 'dark',
      });
    }, 100);
  };

  const mutationLogin = useMutation({
    mutationFn: api.login,
    onSuccess: async () => {
      try {
        const response = await api.getMe();
        const favorites = (await likeListQuery.refetch()).data?.data || [];
        const favoriteIds = favorites.map((item) => {
          return item && item._id;
        });
        authStore.setFavorites(favoriteIds);
        setUser(response.data);
        isNativeApp && setPushAlarmAgree(response.data);
        // eslint-disable-next-line no-empty
      } catch {}
    },
    onError: (e: any) => {
      if (e?.response?.data?.message === 'LOGIN_MISMATCH' && e?.response?.data?.loginType) {
        let snsType = e?.response?.data?.loginType;
        if (e?.response?.data?.loginType === 'kakao') {
          snsType = '카카오톡으로';
        } else if (e?.response?.data?.loginType === 'naver') {
          snsType = '네이버로';
        } else if (e?.response?.data?.loginType === 'apple') {
          snsType = '애플로';
        }

        dialog.open({
          type: 'ok',
          confirmColor: '#FF3D8F',
          title: '로그인 실패',
          content: `${snsType} 가입하신 계정이 있습니다.\n이메일로 로그인이 아닌 해당 서비스를 통해 로그인해주시기 바랍니다.`,
          onConfirm: () => {
            setTimeout(() => {
              appNavigation.back();
            }, 100);
          },
        });
      } else {
        showLoginErrorToast();
      }
    },
  });

  const mutationDeleteAcount = useMutation({
    mutationFn: api.deleteMe,
    onSuccess: async () => {
      setTimeout(() => {
        toast.dark('회원탈퇴 되었습니다.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          bodyStyle: {
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
          theme: 'dark',
        });
      }, 100);

      authStore.setUser(undefined);
      appNavigation.to(`${APP_BASE_PATH}`);
    },
  });

  const mutationLoginLegacy = useMutation({
    mutationFn: api.loginWithLegacyData,
    onSuccess: async () => {
      try {
        const response = await api.getMe();
        const favorites = (await likeListQuery.refetch()).data?.data || [];
        const favoriteIds = favorites.map((item) => {
          return item && item._id;
        });
        authStore.setFavorites(favoriteIds);
        setUser(response.data);
        if (isNativeApp) {
          const message = {
            type: MessageActions.DONE_LEGACY_LOGIN,
            data: {},
          };
          sendMessageToNative(message);
          setPushAlarmAgree(response.data);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
  });

  const mutationJoin = useMutation({
    mutationFn: api.join,
    onSuccess: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        setErrorResponse(undefined);
        isNativeApp && setPushAlarmAgree(response.data);
        appNavigation.to(`${APP_BASE_PATH}`, { replace: true });
        // eslint-disable-next-line no-empty
      } catch {}
    },
    onError: (error) => {
      setErrorResponse(error);
    },
  });

  const mutationLoginWithKakao = useMutation({
    mutationFn: api.loginKakao,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.kakaoUser) {
        appNavigation.to(`${APP_BASE_PATH}join`, { state: { kakaoUser: loginResult.data?.kakaoUser }, replace: true });
      } else {
        try {
          const response = await api.getMe();
          setUser(response.data);
          redirect();
          isNativeApp && setPushAlarmAgree(response.data);
          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        redirect();
        // eslint-disable-next-line no-empty
      } catch {
        appNavigation.to(`${APP_BASE_PATH}`);
        showLoginErrorToast();
      }
    },
  });

  const mutationLoginWithNaver = useMutation({
    mutationFn: api.loginNaver,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.naverUser) {
        appNavigation.to(`${APP_BASE_PATH}join`, { state: { naverUser: loginResult.data?.naverUser }, replace: true });
      } else {
        try {
          const response = await api.getMe();
          setUser(response.data);
          isNativeApp && setPushAlarmAgree(response.data);
          redirect();
          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        redirect();
        // eslint-disable-next-line no-empty
      } catch {
        appNavigation.to(`${APP_BASE_PATH}`);
        showLoginErrorToast();
      }
    },
  });

  const mutationLoginWithApple = useMutation({
    mutationFn: api.loginApple,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.appleUser) {
        appNavigation.to(`${APP_BASE_PATH}join`, { state: { appleUser: loginResult.data?.appleUser }, replace: true });
      } else {
        try {
          const response = await api.getMe();
          setUser(response.data);
          isNativeApp && setPushAlarmAgree(response.data);
          redirect();
          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: () => {
      appNavigation.to(`${APP_BASE_PATH}`);
      showLoginErrorToast();
    },
  });

  const joinWithKakaoMutation = useMutation({
    mutationFn: api.loginKakao,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.kakaoUser) {
        appNavigation.to(`${APP_BASE_PATH}join/referral`, {
          state: { kakaoUser: loginResult.data?.kakaoUser },
          replace: true,
        });
      } else {
        try {
          setTimeout(() => {
            toast.dark('이미 아이고고에 가입되어 있습니다.', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
              bodyStyle: {
                color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              },
              theme: 'dark',
            });
          }, 500);
          const response = await api.getMe();
          setUser(response.data);
          isNativeApp && setPushAlarmAgree(response.data);
          redirect();

          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        redirect();
        // eslint-disable-next-line no-empty
      } catch {
        appNavigation.to(`${APP_BASE_PATH}`);
        showLoginErrorToast();
      }
    },
  });

  const joinWithNaverMutation = useMutation({
    mutationFn: api.loginNaver,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.naverUser) {
        appNavigation.to(`${APP_BASE_PATH}join/referral`, {
          state: { naverUser: loginResult.data?.naverUser },
          replace: true,
        });
      } else {
        try {
          setTimeout(() => {
            toast.dark('이미 아이고고에 가입되어 있습니다.', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
              bodyStyle: {
                color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              },
              theme: 'dark',
            });
          }, 500);

          const response = await api.getMe();
          setUser(response.data);
          isNativeApp && setPushAlarmAgree(response.data);
          redirect();
          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        redirect();
        // eslint-disable-next-line no-empty
      } catch {
        appNavigation.to(`${APP_BASE_PATH}`);
        showLoginErrorToast();
      }
    },
  });

  const joinWithAppleMutation = useMutation({
    mutationFn: api.loginApple,
    onSuccess: async (loginResult) => {
      if (loginResult.data?.appleUser) {
        appNavigation.to(`${APP_BASE_PATH}join/referral`, {
          state: { appleUser: loginResult.data?.appleUser },
          replace: true,
        });
      } else {
        try {
          setTimeout(() => {
            toast.dark('이미 아이고고에 가입되어 있습니다.', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              pauseOnFocusLoss: false,
              draggable: true,
              progress: undefined,
              bodyStyle: {
                color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              },
              theme: 'dark',
            });
          }, 500);
          const response = await api.getMe();
          setUser(response.data);
          isNativeApp && setPushAlarmAgree(response.data);
          redirect();
          // eslint-disable-next-line no-empty
        } catch {
          redirect();
        }
      }
    },
    onError: () => {
      appNavigation.to(`${APP_BASE_PATH}`);
      showLoginErrorToast();
    },
  });

  const mutationLoginAsUser = useMutation({
    mutationFn: api.loginAsUser,
    onSuccess: async () => {
      try {
        const response = await api.getMe();
        setUser(response.data);
        // eslint-disable-next-line no-empty
      } catch {}
    },
    onError: () => {
      showLoginErrorToast();
    },
  });

  const logoutWithoutBackMutation = useMutation({
    mutationFn: api.logout,
    onSuccess: () => {
      Cookies.remove('Authentication');
      Cookies.remove('AuthRefresh');
      setUser(undefined);
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');

      setTimeout(() => {
        toast.dark('로그아웃 되었습니다.', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          bodyStyle: {
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
          theme: 'dark',
        });
      }, 100);
    },
    onError: () => {
      setUser(undefined);
    },
  });

  const mutationLogout = useMutation({
    onMutate: () => {
      setAfterLoginRedirectUrl(document.location.pathname);
    },
    mutationFn: api.logout,
    onSuccess: () => {
      if (document.location.pathname.startsWith('/seller')) {
        window.location.href = '/seller/login';
      } else {
        appNavigation.back();
      }

      Cookies.remove('Authentication');
      Cookies.remove('AuthRefresh');
      setUser(undefined);
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');

      setTimeout(() => {
        toast.dark('로그아웃 되었습니다.', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          pauseOnFocusLoss: false,
          draggable: true,
          progress: undefined,
          bodyStyle: {
            color: 'var(--White-90, rgba(255, 255, 255, 0.90))',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          },
          theme: 'dark',
        });
      }, 100);
    },
    onError: () => {
      setUser(undefined);
    },
  });

  const checkLogin = async (loginPopup?: boolean) => {
    let user = undefined;
    try {
      const response = await api.getMe();
      if (response?.data?.deletedAt) {
        logout();
      }
      user = response?.data;

      // eslint-disable-next-line no-empty
    } catch {}

    setUser(user);

    if (user && !options?.tutor && !options?.seller) {
      const favorites = (await likeListQuery.refetch()).data?.data || [];
      const favoriteIds = favorites.map((item) => {
        return item && item._id;
      });
      authStore.setFavorites(favoriteIds);
    }

    if (!user && (options?.loginRequired || loginPopup)) {
      if (options?.tutor) {
        appNavigation.to('/tutor');
      } else if (options?.seller) {
        appNavigation.to('/seller/login');
      } else {
        appLoginModal.open({ closeAndGoBack: options?.closeAndGoBack });
      }
    }

    if (options?.tutor && user?.role !== Roles.TUTOR) {
      logout();
      appNavigation.to('/tutor');
    }

    if (options?.seller && user?.role !== Roles.SELLER) {
      logout();
      appNavigation.to('/seller/login');
    }

    return !!user;
  };

  const refreshLogin = () => {
    if (needRefresh.current) {
      needRefresh.current = false;
      if (authStore.status !== LoginStatus.ANONYMOUS || options?.loginRequired) {
        checkLogin();
      }

      setTimeout(() => {
        needRefresh.current = true;
      }, 1000);
    }
  };

  useEffect(() => {
    if (!options?.disableLoginRefresh) {
      refreshLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {
    appSocket.sendEvent({ type: 'logout', data: authStore.user });
    mutationLogout.mutate();
  };

  return {
    authStore,
    login: mutationLogin.mutate,
    loginLegacy: mutationLoginLegacy.mutate,
    loginError: mutationLogin.error,
    logout,
    logoutWithoutBack: logoutWithoutBackMutation.mutate,
    loginKakao: mutationLoginWithKakao.mutate,
    loginNaver: mutationLoginWithNaver.mutate,
    loginApple: mutationLoginWithApple.mutate,
    joinKakao: joinWithKakaoMutation.mutate,
    joinNaver: joinWithNaverMutation.mutate,
    joinApple: joinWithAppleMutation.mutate,
    loginAsUserByAdmin: mutationLoginAsUser.mutate,
    join: mutationJoin.mutate,
    error: errorResponse,
    setRedirectUrl: setAfterLoginRedirectUrl,
    checkLogin: checkLogin,
    deleteMe: mutationDeleteAcount.mutate,
  };
};
