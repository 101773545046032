import React from 'react';

import CheckedCircle from '@assets/images/app/checked-circle.png';
import CellTitle from '@components/app/class-invoice/CellTitle';
import ClassAddressView from '@components/app/class-invoice/ClassAddressView';
import { ClassInvoiceSummary } from '@components/app/class-invoice/ClassInvoiceSummary';
import Divider from '@components/app/class-invoice/Divider';
import PaymentResult from '@components/app/class-invoice/PaymentResult';
import BottomRoundButton from '@components/common/BottomRoundButton';
import { useAppNavigation } from '@hooks/appNavigation';
import { ClassInvoice } from '@models/invoice';
import { PaymentStatement } from '@models/purchase';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import './ClassInvoice.scss';
import { APP_BASE_PATH } from '@variables';
import { usePayment } from '@hooks/payment';
import { useClassInvoiceModalStore } from '@stores/classInvoiceModalStore';

interface ClassInvoicePaymentCompleteProps {
  classInvoice: ClassInvoice;
  paymentStatement?: PaymentStatement;
}

export const ClassInvoicePaymentComplete: React.FC<ClassInvoicePaymentCompleteProps> = (props) => {
  const appNavigation = useAppNavigation();
  const payment = usePayment();
  const classInvoiceModalStore = useClassInvoiceModalStore();
  const { classInvoice, paymentStatement } = props;
  const selectedChildrenList = classInvoice?.students || [];
  const otherChildrenCount = classInvoice?.additionalParticipantCount || 0;

  const classThumbnail = classInvoice.classThumbnail;
  return (
    <OverlayScrollbarsComponent
      defer
      options={{
        scrollbars: { autoHide: 'scroll' },
        overflow: {
          x: 'hidden',
        },
      }}
      style={{
        backgroundColor: '#ffffff',
        marginTop: 0,
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        width: '100%',
      }}>
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
            <img src={CheckedCircle} alt=""></img>
            <div
              style={{
                marginTop: '16px',
                color: 'var(--Neutral-100, #242424)',
                textAlign: 'center',
                fontFamily: 'Pretendard',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
              }}>
              결제가 <span style={{ color: '#FF3D8F' }}>완료</span>되었습니다.
            </div>
          </div>
          <div style={{ width: '100%', marginTop: '8px', padding: '20px 16px', backgroundColor: 'white' }}>
            <ClassInvoiceSummary
              tutorProfileImage={classInvoice.tutorProfileImage}
              tutorName={classInvoice.tutorName}
              classTitle={classInvoice.classTitle}
              classThumbnail={classThumbnail}
              students={selectedChildrenList}
              additionalParticipantCount={otherChildrenCount}
              totalClassTimes={classInvoice.totalClassTimes}
              classDuration={classInvoice.classDuration}
              schedules={classInvoice.schedules}
            />
          </div>
          {paymentStatement ? (
            <div style={{ width: '100%' }}>
              <Divider />
              <ClassAddressView address={classInvoice.address} addressDetail={classInvoice.addressDetail} />
              <Divider />
              <PaymentResult
                classFee={classInvoice.classFeePerTime}
                totalClassTimes={classInvoice.totalClassTimes}
                totalStudentCount={selectedChildrenList.length + otherChildrenCount}
                applyGroupDiscount={classInvoice.applyGroupDiscount}
                payment={paymentStatement.payment}
              />
            </div>
          ) : (
            <div style={{ height: '48px' }}>결제 완료 데이터가 없습니다.</div>
          )}
          <Divider />
          <div style={{ width: '100%', padding: '28px 20px' }}>
            <CellTitle>
              <div>취소 및 환불규정</div>
            </CellTitle>
            <div
              style={{
                paddingLeft: '0',
                marginTop: '16px',
                whiteSpace: 'pre-wrap',
                color: 'var(--Neutral-70, #808387)',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              }}>
              {
                '배송 및 교환 그리고 환불은 판매자가 직접 진행을 합니다.\n아래의 방법으로 접수를 해주시면 해당 판매자 측으로 아이고고에서 연락을 취해 교환/환불이 접수/처리 됩니다.'
              }
            </div>
            <div
              style={{
                marginTop: '16px',
                color: 'var(--Neutral-80, #66686B)',
                fontFamily: 'Pretendard',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '22px',
              }}>
              환불 안내
            </div>
            <div
              style={{
                marginTop: '8px',
                color: 'var(--Neutral-70, #808387)',
                fontFamily: 'Pretendard',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '20px',
              }}>
              <li>환불 안내</li>
              <li>일정 3일 전까지 환불 요청 가능합니다.</li>
              <li>환불 기간 내 요청은 100% 환불됩니다.</li>
            </div>
            <div
              style={{
                marginTop: '28px',
                width: '100%',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
              }}>
              <BottomRoundButton
                theme="secondary"
                style={{ flex: 1 }}
                onClick={() => {
                  classInvoiceModalStore.close();
                  payment.clear();
                  appNavigation.to(`${APP_BASE_PATH}`);
                }}>
                홈으로
              </BottomRoundButton>
              <BottomRoundButton
                style={{ flex: 1, marginLeft: '8px' }}
                onClick={() => {
                  payment.clear();
                  classInvoiceModalStore.open({
                    classId: classInvoice.classId || classInvoice.programId,
                    classInvoiceId: classInvoice._id,
                    mode: 'view',
                  });
                }}>
                수강 상세
              </BottomRoundButton>
            </div>
          </div>
        </div>
      </div>
    </OverlayScrollbarsComponent>
  );
};
