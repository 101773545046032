import React from 'react';
import LoaderImage from '@assets/images/app/loading_img.gif';

export const AppLoader: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100% - 300px)',
        backgroundColor: 'rgba(255,255,255,0.7)',
      }}>
      <img style={{ width: '100px', height: '50px' }} src={LoaderImage} alt="" />
    </div>
  );
};
