import React from 'react';

interface SlideItemProps {
  image: string;
  width: number;
  height: number;
  transform: string;
  clipPath: string;
  scale: number;
  blur: number;
  onClick: () => void;
}

const SlideItem: React.FC<SlideItemProps> = ({ image, width, height, transform, clipPath, scale, blur, onClick }) => (
  <div
    style={{
      position: 'absolute',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      transition: 'all 0.5s',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.3)',
      cursor: 'pointer',
      backgroundImage: `url(${image})`,
      width: `${width}px`,
      height: `${height}px`,
      transform: `${transform} scale(${scale})`,
      clipPath: clipPath,
      filter: `blur(${blur}px)`,
      borderRadius: '12px',
    }}
    onClick={onClick}
  />
);

export default SlideItem;
