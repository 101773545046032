import { useQuery } from '@tanstack/react-query';
import { CDN_BASE_URL, STAGE, WEB_VERSION } from '@variables';
import axios from 'axios';
import { compareVersion } from './appPopup';

export const useAppVersion = () => {
  let isUpdateAvailable = false;
  const configUrl = `${CDN_BASE_URL}/config/${STAGE}_web_version`;

  const getRemoteConfigWebVersionQuery = useQuery({
    queryKey: [`remote-config-web-version-${WEB_VERSION}`],
    queryFn: () => axios.get(configUrl),
  });

  const latestVersion = getRemoteConfigWebVersionQuery.data?.data;
  const currentVersion = WEB_VERSION.trim();
  if (latestVersion) {
    if (STAGE === 'dev') {
      if (latestVersion.trim() !== currentVersion) {
        isUpdateAvailable = true;
      } else {
        isUpdateAvailable = false;
      }
    } else if (STAGE === 'prod') {
      isUpdateAvailable = compareVersion(currentVersion, latestVersion.trim());
    }
  }

  const refreshVersion = localStorage.getItem('refresh-version');
  if (isUpdateAvailable && refreshVersion !== latestVersion) {
    localStorage.setItem('refresh-version', latestVersion);
    window.location.reload();
  }

  if (STAGE === 'local' || STAGE === 'local.app') {
    return { isUpdateAvailable: false };
  }

  return { isUpdateAvailable };
};
