import React, { useEffect, useState } from 'react';
import ClearIcon from '@assets/images/app/x-circle.png';

export interface PointUseProps {
  userPoint: number;
  totalFee: number;
  productShippingFee: number;
  onChangeApplyPoint?: (point: number) => void;
}

export const PointUse: React.FC<PointUseProps> = ({ userPoint, totalFee, productShippingFee, onChangeApplyPoint }) => {
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [applyPoint, setApplyPoint] = useState<number>(0);

  let maxPoint = userPoint;
  if (totalFee + productShippingFee < maxPoint) {
    maxPoint = totalFee + productShippingFee;
  }

  useEffect(() => {
    onChangeApplyPoint && onChangeApplyPoint(applyPoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyPoint]);

  return (
    <div
      style={{
        marginTop: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        marginBottom: '24px',
      }}>
      <div
        style={{
          fontSize: '18px',
          lineHeight: '21px',
          color: '#242424',
          fontWeight: 'bold',
        }}>
        포인트
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '20px',
        }}>
        <div
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            color: '#424242',
          }}>
          사용가능 포인트
        </div>
        <div
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            color: '#242424',
            fontWeight: 'bold',
            marginLeft: '8px',
          }}>
          {(userPoint - applyPoint).toLocaleString()}P
        </div>
      </div>
      <div
        style={{
          marginTop: '8px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <div
          style={{
            width: '100%',
            height: '48px',
            borderRadius: '8px',
            border: inputFocus ? '1px solid #242424' : '1px solid #E8EAED',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <input
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              color: 'var(--Primary-95, #FF3D8F)',
              textAlign: 'right',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}
            placeholder={'0'}
            value={applyPoint.toLocaleString()}
            onChange={(e) => {
              setApplyPoint(() => {
                const newPoint = Number(e.target.value.replace(/[^\d.-]+/g, ''));

                if (newPoint > maxPoint) {
                  return maxPoint;
                }

                if (totalFee + productShippingFee - newPoint < 100 && totalFee + productShippingFee - newPoint > 0) {
                  return totalFee + productShippingFee - 100;
                }

                return newPoint;
              });
            }}
            onFocus={() => {
              setInputFocus(true);
            }}
            onBlur={() => {
              setTimeout(() => {
                setInputFocus(false);
              }, 200);
            }}
          />
          <div
            style={{
              flexShrink: 0,
              color: 'var(--Primary-95, #FF3D8F)',
              textAlign: 'right',
              fontFamily: 'Pretendard',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 700,
            }}>
            원
          </div>
          {inputFocus && (
            <button
              className="link-button"
              style={{ marginLeft: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setApplyPoint(0);
              }}>
              <img src={ClearIcon} alt=""></img>
            </button>
          )}
        </div>
        <button
          className="link-button"
          style={{
            display: 'flex',
            height: '48px',
            width: '120px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '8px',
            backgroundColor: 'white',
            marginLeft: '8px',
            color: 'var(--Neutral-90, #424242)',
            textAlign: 'center',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            border: '1px solid #E8EAED',
          }}
          onClick={() => {
            let newPoint = maxPoint;
            if (totalFee + productShippingFee - newPoint < 100 && totalFee + productShippingFee - newPoint > 0) {
              newPoint = totalFee + productShippingFee - 100;
            }

            setApplyPoint(newPoint);
          }}>
          전액 사용
        </button>
      </div>
    </div>
  );
};

export default PointUse;
